var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        [
          _c("h1", [_vm._v("Company Logo")]),
          _c("div", { staticClass: "mt-3 mb-3" }, [
            !_vm.avatar ? _c("span", [_vm._v("No Avatar")]) : _vm._e(),
            _c("img", { staticClass: "vendorlogo", attrs: { src: _vm.avatar } })
          ]),
          _c("b-row", [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("input", {
                  staticClass: "ml-3 primary btn-sm",
                  staticStyle: { width: "190px" },
                  attrs: { accept: "image/x-png,image/jpeg", type: "file" },
                  on: {
                    change: function($event) {
                      return _vm.onFileChanged($event)
                    }
                  }
                }),
                _c(
                  "b-btn",
                  {
                    attrs: { variant: "primary btn-sm" },
                    on: {
                      click: function($event) {
                        return _vm.uploadAvatar()
                      }
                    }
                  },
                  [_vm._v("Upload Avatar")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }