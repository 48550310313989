<template>
  <div class="animated fadeIn">         
    <b-card>
        <h1>Company Logo</h1>
        <div class="mt-3 mb-3">   
          <span v-if="!avatar">No Avatar</span> <img class="vendorlogo" v-bind:src="avatar" />
        </div>
        <b-row>
          <div class="col-md-4">
              <input class="ml-3 primary btn-sm" accept="image/x-png,image/jpeg" type="file" style="width: 190px" @change="onFileChanged($event)">
              <b-btn @click="uploadAvatar()" variant="primary btn-sm">Upload Avatar</b-btn>
          </div>
        </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import * as jwt_decode from 'jwt-decode';

export default {
  name: 'VendorEditProfile',
  data: function () {
    return {
      avatar : null,
    }
  },
  mounted: function () {
    let token = this.$session.get('jwt');
      var decoded = jwt_decode(token);

      //  Get the avatar
      this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
        this.avatar = response.data.avatar;
      }).catch(error => {
        console.log("Error getting avatar " + error);
      })    
  },
  methods: {
    onFileChanged(event){
      this.selectedAvatarToUpload = event.target.files[0]
      console.log("Got a new file");
      console.log(this.selectedAvatarToUpload);
    },
    async readSelectedFile(){
      var reader = new FileReader();
      return new Promise((resolve,reject) => {
        var file = this.selectedAvatarToUpload;

        reader.onerror = () => {
          reader.abort();
          reject("Somthing went wrong reading the file");
        }

        reader.onloadend = () => {    
          console.log("read the file ");
          console.log(reader.result);
          resolve(reader.result);   
        }

        reader.readAsDataURL(file);
      });  
    },
    async uploadAvatar(){
      let avatar;
      
      try {
        avatar = await this.readSelectedFile();
      } catch (err) {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
        return;
      }

      let payload = {
        "avatar" : avatar
      }
      
      console.log("sending payload");
      console.log(payload);
      
      this.$http.post('vendor/edit/avatar',payload).then(response => {
        if (response.status === 200) {
          this.$toasted.show('Avatar Upload Success!', {type: 'success', duration: '3000'});
          this.avatar = avatar;
        } else {
          this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
        } 
      }).catch(error => {
        this.$toasted.show('Avatar upload failed: Must be either .JPG .PNG and smaller than 110kb', {type: 'error', duration: '3000'});
      })        
    },
  }
}
</script>

<style>
    .vendorlogo{
      max-width: 150px;
      max-height: 50px;
    }
</style>

